/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';
import getInsightsReports from '../api/insightsReportsThunk';

const setPendingState = (state) => {
    state.status = merge(state.status, {loading: true});
};

const setRejectedState = (state, action) => {
    state.status = merge(state.status, {loading: false});
    state.error = action.error.message;
};

const setGetFulfilledState = (state, action) => {
    state.status = merge(state.status, {
        loading: false,
        added: false,
        deleted: false
    });
    state.reports = action.payload.rows;
};

const initialState = {
    error: null,
    status: {loading: false},
    reports: []
};

const insightsReportsSlice = createSlice({
    name: 'insightsReports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getInsightsReports.fulfilled, setGetFulfilledState)
            .addMatcher(
                (action) => action.type.endsWith('/pending') && action.type.includes('insightsReports'),
                setPendingState
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected') && action.type.includes('insightsReports'),
                setRejectedState
            );
    }
});

export default insightsReportsSlice.reducer;
